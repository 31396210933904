import React, { useEffect, useState }  from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import Layout from '../../containers/layout';
import OmxSingle from '../../components/omxSingle';
import keygen from '../../utils/keygen';
import * as R  from 'ramda';

import '../style.scss';

const IndexPage = ({ data, location, microcopy }) => {

  const releases = data.posts.edges;
  
  let matchingRelease = null;
  const omx_id = location.search.split('?omx_id=')[1];
  if(omx_id) {
    /* eslint-disable-next-line */
     const omxIdMatch = (item) => item.node.omx_id == omx_id;
     matchingRelease = R.head(R.filter(omxIdMatch, releases));
   
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : "server";
    
  useEffect(() => {
    setClient(true);
  }, []);
    
  if ( !isClient ) return null;
 
  const microcopyHeadline = R.head(R.filter(item => item.node.slug === 'releases-archive-headline-fi', microcopy));
  const headlineText = R.pathOr('',['node', 'singleLineContent'], microcopyHeadline);
 

  return (
    <div key={key}>    
     {!omx_id && matchingRelease === null ?
    <Layout location={location} title="releases archive from omx helsinki" description="releases archive from omx helsinki">
 
      <div className="releasesContainer">
          <h1>{headlineText}</h1>
          {releases.map((release) => {
          return(
          <div className="releaseItem"  key={keygen()}><a href={`/releasesfi/?omx_id=${release.node.omx_id}`}>{release.node.post_title}</a></div>);
           })}
      </div> 
  </Layout> : 
  <OmxSingle matchingRelease={matchingRelease} backlink="/releasesfi/" location={location} /> 
  }
  
  </div>)
}

export default connect(state => ({
  microcopy: state.app.microcopy
}), null)(IndexPage);

export const pageQuery = graphql`
  query releaseArchiveFiQuery {
    posts: allReleasesfiJson {
      edges {
        node {
          id
          post_title
          post_date
          omx_id
          post_content
          annex_files {
            file
            title
          }
        }
      }
    }
  }
`